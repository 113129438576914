// Lists with a straight left margin.

%ul-straight-left {
  display: table;
  list-style: none;
  padding: 0;

  & > li {
    display: table-row;

    &::before {
      @include font-size(s);
      @include padding-right(.5);
      display: table-cell;
      content: '\2022';

      @include respond-to(xxl) {
        @include font-size(s-2);
      }
    }
  }
}

%ol-straight-left {
  display: table;
  list-style: none;
  padding: 0;

  & > li {
    display: table-row;
    counter-increment: table-ol;

    &::before {
      @include font-size(s);
      @include padding-right(.5);
      display: table-cell;
      content: counter(table-ol) '.';
      text-align: right;

      @include respond-to(xxl) {
        @include font-size(s-2);
      }
    }
  }
}

%ul-straight-left--off {
  display: block;
  list-style: inherit;

  & > li {
    display: list-item;

    &::before {
      content: '';
      display: inline;
      padding: inherit;
    }
  }
}
