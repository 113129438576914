$with-wireframes: false !default;

// Wireframe
//
// Add wireframes around main layout elements. [Wireframes](http://www.boxesandarrows.com/view/html_wireframes_and_prototypes_all_gain_and_no_pain)
// are useful when prototyping a website.
//
// This design is not applied to the site unless you set the following Sass
// variable: `$with-wireframes: true;`

@if $with-wireframes {

  .wireframe,
  %wireframe {
    outline: 1px solid $border;
  }

  //
  // Zen selectors.
  //

  .layout {
    &__header,
    &__footer,
    &__main,
    &__navigation,
    &__first-sidebar,
    &__second-sidebar,
    &__page-top,
    &__page-bottom {
      @extend %wireframe;
    }
  }

}
