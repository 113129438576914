// Highlight mark
//
// The "new" or "updated" marker. This is a very thin component. A front-end
// developer may choose to delete this component and just style the `<mark>`
// element directly.

.highlight-mark,
%highlight-mark {
  color: $mark-highlight;
  // Remove background highlighting from <mark> in normalize.
  background-color: transparent;
}
