// Dependencies.
@import 'components/visually-hidden/visually-hidden';

// Navbar
//
// A simple method to get navigation links to appear in one line.

.navbar,
%navbar {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  text-align: left;

  @include rtl() {
    flex-direction: row-reverse;
    text-align: right;
  }

  &__item,
  li {
    padding: 0 10px 0 0;
    list-style-type: none;
    list-style-image: none;

    @include rtl() {
      padding: 0 0 0 10px;
    }
  }
}
