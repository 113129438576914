// sass-lint:disable no-color-keywords; no-color-hex

// Base colors.
$black:                  #000;
$nearblack:              lighten($black, 20%); // #333
$grey-dark:              lighten($black, 40%); // #666
$grey:                   lighten($black, 60%); // #999
$grey-light:             lighten($black, 80%); // #ccc
$grey-extra-light:       lighten($black, 93.33%); // #eee
$white:                  #fff;

$blue:                   #0072b9;
$red:                    #c00;
$green:                  #43a808;
$yellow:                 #fd0;

// Colors used in the main content area.
$brand:                  $nearblack;
$brand-alt:              $grey;
$brand-dark:             $grey-dark;
$text:                   $black;
$text-bg:                $white;
$text-meta:              $grey-dark;

$link:                   $black;
$link-visited:           $black;
$link-hover:             $brand;
$link-active:            $red;

$border:                 $grey-light;
$border-dark:            $nearblack;

$autocomplete:           $text;
$autocomplete-bg:        $text-bg;
$autocomplete-select:    $white;
$autocomplete-select-bg: $blue;

$body-bg:                $white;
$header-bg:              $brand;
$backdrop:               $grey-extra-light;

$mobile-menu:            $header-bg;
$mobile-menu-cover:      rgba($black, .2);

$button:                 $nearblack;
$button-disabled:        $grey;

$form-error:             $red;

$mark-highlight:         $red;
$mark-bg:                $yellow;

$menu-active:            $text;

$preview-bg:             lighten($yellow, 43%);

$row-stripe:             $white;
$row-disabled:           $grey-light;

$status:                 $green;
$status-bg:              lighten($status, 62%);
$status-highlight:       $text;
$warning:                $text;
$warning-bg:             lighten($yellow, 45%);
$warning-border:         $yellow;
$error:                  $red;
$error-bg:               lighten($error, 57%);

$watermark:              $grey-extra-light;

$headings:               $nearblack;

$code:                   $nearblack;
