// Watermark
//
// Make the element appear beneath sibling elements, like a watermark.

.watermark,
%watermark {
  @include typeface(compact);
  display: block;
  height: 0;
  overflow: visible;
  // Remove background highlighting from <mark> in normalize.
  background-color: transparent;
  color: $watermark;
  font-size: 75px;
  line-height: 1;
  text-align: center;
  // sass-lint:disable no-color-literals
  text-shadow: 0 0 1px rgba(0, 0, 0, .1);
  word-wrap: break-word;
}
