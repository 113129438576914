// Embedded content
//
// Weight: 2

// Audio

audio {
  // Add the correct display in IE 9-.
  display: inline-block;
}

// Add the correct display in iOS 4-7.
audio:not([controls]) {
  display: none;
  height: 0;
}

// Image
//
// An `<img>` element represents an image.

img {
  // Remove the border on images inside links in IE 10-.
  border-style: none;
}

img,
svg {
  // Suppress the space beneath the baseline
  // vertical-align: bottom;

  // Responsive images
  max-width: 100%;
  height: auto;
}

// Scalable vector
//
// A `<svg>` element represents an image encoded as a Scalable Vector Graphic.

svg:not(:root) {
  // Hide the overflow in IE.
  overflow: hidden;
}

// Video

video {
  // Add the correct display in IE 9-.
  display: inline-block;
}
