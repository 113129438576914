// Normalize.css is intended to be used as an alternative to CSS resets.
//
// @see http://nicolasgallagher.com/about-normalize-css/
//
// Also: @see http://meiert.com/en/blog/20080419/reset-style-sheets-are-bad/
//       @see http://snook.ca/archives/html_and_css/no_css_reset/
//
// This directory is a slight fork of this original source:
// normalize-scss 7.0.0 | MIT/GPLv2 License | bit.ly/normalize-scss


// Universal
// ==========================================================================

// Use the saner border-box model for all elements.
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

// Normalize-scss is broken into modular pieces to make it easier to edit.
@import 'document/document';
@import 'sections/sections';
@import 'grouping/grouping';
@import 'links/links';
@import 'text/text';
@import 'embedded/embedded';
@import 'forms/forms';
@import 'tables/tables';
@import 'interactive/interactive';
@import 'scripting/scripting';
@import 'hidden/hidden';

// Note: we allow the .button component (loaded by forms) to override :link, by
// loading links first.
