// Sections
// ==========================================================================


body {
  // Output a horizontal grid to help with debugging typography. The
  // $typey-debug variable will toggle its output.
  @include typey-debug-grid();
  // Remove the margin in all browsers (opinionated).
  margin: 0;
  padding: 0;
}

// Add the correct display in IE 9-.
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

// Headings
//
// All HTML headings, `<h1>` through `<h6>`, are available. `%h1` through `%h6`
// classes are also available, for when you want to match the font styling of a
// heading but still want your text to be displayed inline.

// Correct the font size and margin on `h1` elements within `section` and
// `article` contexts in Chrome, Firefox, and Safari.
h1,
%h1 {
  // Set the font-size and line-height while keeping a proper vertical rhythm.
  @include type-layout(xxl, 1.5);

  // Set 1 unit of vertical rhythm on the top and bottom margins.
  @include margin-top(1, xxl);
  @include margin-bottom(1, xxl);
}

h2,
%h2 {
  @include type-layout(xl, 1.5);
  @include margin-top(1, xl);
  @include margin-bottom(1, xl);
}

h3,
%h3 {
  @include type-layout(l, 1.5);
  @include margin-top(1, l);
  @include margin-bottom(1, l);
}

h4,
%h4 {
  @include type-layout(m, 1);
  @include margin-top(1, m);
  @include margin-bottom(1, m);
}

h5,
%h5 {
  @include type-layout(s, 1);
  @include margin-top(1, s);
  @include margin-bottom(1, s);
}

h6,
%h6 {
  @include type-layout(xs, 1);
  @include margin-top(1, xs);
  @include margin-bottom(1, xs);
}

@include respond-to(s) {
  h1,
  %h1 {
    // Set the font-size and line-height while keeping a proper vertical rhythm.
    @include type-layout(xxl-2, 2);

    // Set 1 unit of vertical rhythm on the top and bottom margins.
    @include margin-top(1, xxl-2);
    @include margin-bottom(1, xxl-2);
  }

  h2,
  %h2 {
    @include type-layout(xl-2, 1.5);
    @include margin-top(1, xl-2);
    @include margin-bottom(1, xl-2);
  }

  h3,
  %h3 {
    @include type-layout(l-2, 1.5);
    @include margin-top(1, l-2);
    @include margin-bottom(1, l-2);
  }

  h4,
  %h4 {
    @include type-layout(m-2, 1);
    @include margin-top(1, m-2);
    @include margin-bottom(1, m-2);
  }

  h5,
  %h5 {
    @include type-layout(s-2, 1);
    @include margin-top(1, s-2);
    @include margin-bottom(1, s-2);
  }

  h6,
  %h6 {
    @include type-layout(xs-2, 1);
    @include margin-top(1, xs-2);
    @include margin-bottom(1, xs-2);
  }
}
